import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import LoadingScreen from './components/LoadingScreen';
import HelpPrompt from './components/HelpPrompt';
import InterfaceUI from './components/InterfaceUI';
import eventBus from './EventBus';
import { Analytics } from '@vercel/analytics/react';
import './style.css';

const App = () => {
    const [loading, setLoading] = useState(true);

    // Redirect mobile users before anything else
    if (/Mobi|Android/i.test(navigator.userAgent)) {
        window.location.href = 'https://pepecoin.io';
        return null; // Return null to stop rendering the rest of the component
    }

    useEffect(() => {
        eventBus.on('loadingScreenDone', () => {
            setLoading(false);
        });
    }, []);

    return (
        <div id="ui-app">
            {!loading && <HelpPrompt />}
            <LoadingScreen />
            <Analytics />
        </div>
    );
};

const createUI = () => {
    ReactDOM.render(<App />, document.getElementById('ui'));
};

const createVolumeUI = () => {
    ReactDOM.render(<InterfaceUI />, document.getElementById('ui-interactive'));
};

export { createUI, createVolumeUI };
